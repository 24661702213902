<template>
<div id="online">
  <!--<div class="btn" @click="goTo('telemedicine')">WEB予約 / オンライン診療受付</div>-->
  <!--<div class="btn disabled">WEB予約 / オンライン診療受付（工事中）</div>-->
  <div class="btn" @click="goHref('https://www.tecot.go.jp/')">PCR検査 (海外渡航者の方)</div>
  <div class="btn" @click="goTo('/pcrcn')">PCR検査 (中国渡航者の方)</div>
</div>
</template>

<script>
export default {
  name: "MOnline",
  methods: {
    goTo(page) {
      this.$router.push(page)
    },
    goHref(path) {
      window.location.href = path;
    }
  }
}
</script>

<style scoped>
#online {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
}
#online .btn:nth-child(1) {
  background-color: #D14444;
}
#online .btn:nth-child(2) {
  background-color: #004686;
}
.btn {
  margin: 1rem 0;
  padding-left: 2rem;

  color: #FFFFFF;
  font-size: 14px;
  border-radius: 10px;
  height: 60px;
  line-height: 60px;
}
</style>

<template>
  <el-carousel indicator-position="inside"
                            :height="carouselHeight+'px'"
                            trigger="click"
                            :interval="6000">
                <el-carousel-item v-for="item in picNameList" :key="item">
                  <el-image id="carouselImage" :src="require(`@/assets/carousel/${item}`)"/>
                </el-carousel-item>
              </el-carousel>
</template>

<script>

export default {
  name: "MSwiper",
  data() {
    return {
      picNameList: [
        'home_01.png',
        'home_02.png',
        'home_03.png',
        'IMG_0054.png',
      ],
    }
  }
}
</script>

<style scoped>
.el-carousel__item.is-animating {
    -webkit-transition: -webkit-transform 2s ease-in-out;
    transition: -webkit-transform 2s ease-in-out;
    transition: transform 2s ease-in-out;
    transition: transform 2s ease-in-out,-webkit-transform 2s ease-in-out;
}
</style>

<template>
<div id="online" class="online">
  <div class="btn" @click="goTo('telemedicine')">
    <div>
      <img src="../../assets/icon/icon_calendar_36x36@2x.png" alt="" width="36px" height="36px">
    </div>
    <div class="text">
      <div class="text-web">WEB予約</div>
      <div class="text-online">オンライン診療</div>
    </div>
  </div>
  <div class="info">
    <div class="info-title">受診予約のご案内</div>
    <div class="info-content">
      <div>
        <span class="content-title">診療時間</span>
        <span class="content-text">10:00 ~ 13:30 / 14:30 ~ 19:00</span>
      </div>
      <div>
        <span class="content-title">休診日　</span>
        <span class="content-text">日曜・祝日・年末年始</span>
      </div>
    </div>
    <div class="info-tel">
      <div><img src="../../assets/icon/icon_tel_hover_28x28@2x.png" alt="" width="24px" height="24px"></div>
      <div class="tel-number">03-6260-6008</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "HomeOnline",
  methods: {
    goTo(page) {
      this.$router.push(`/department/${page}`)
    }
  }
}
</script>

<style scoped>
/* オンライン予約 */
.btn {
  align-items: center;
  background-color: #62B6E2;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  box-shadow:  2px 0 4px #AAAAAA;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  width: 240px;
}
.btn:hover {
  background-color: #40A3D8;
}
.text {
  margin-left: 10px;
}
.text-web {
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}
.text-online {
  font-weight: bold;
  font-size: 12px;
  color: #FFFFFF;
  margin-top: 4px;
}
/* 病院情報 */
.info {
  background-color: #FFFFFF;
  border-radius: 10px;
  opacity: 0.9;
  padding: 30px;
  margin-top: 18px;
  width: 480px;
}
.info .info-title {
  color: #62B6E2;
  font-size: 20px;
  font-weight: bold;
}
.info .info-content {
  margin-top: 20px;
}
.info .content-title {
  color: #62B6E2;
  font-size: 14px;
  font-weight: bold;
}
.info .content-text {
  font-size: 14px;
  margin-left: 20px;
}
.info .info-tel {
  align-items: center;
  display: flex;
  margin-top: 24px;
}
.info .tel-number {
  font-size: 24px;
  font-weight: bold;
  margin-left: 14px;
}
</style>

<template>
  <div id="index">
    <div class="hidden-sm-and-down">
      <PageHeader/>
    </div>
    <div class="hidden-md-and-up">
      <m-top/>
    </div>
    <div class="hidden-xs-only">




      <el-row type="flex" justify="center">
        <el-col :md="20" :lg="18" :xl="15">
          <div style="width: 100%;max-width: 1284px; margin: 0 auto;">
            <div class="carousel">
              <el-carousel indicator-position="inside"
                            :height="carouselHeight+'px'"
                            trigger="click"
                            :interval="6000">
                <el-carousel-item v-for="item in picNameList" :key="item">
                  <el-image id="carouselImage" :src="require(`@/assets/carousel/${item}`)"/>
                </el-carousel-item>
              </el-carousel>
              <!-- <div class="home-online">
                <HomeOnline/>
              </div> -->
            </div>
            <div class="info-blog">
              <el-row>
                <el-col :span="15">
                  <HomeInformation/>
                </el-col>
                <el-col :span="8">
                  <HomeSNS/>
                </el-col>
              </el-row>
            </div>

          </div>

        </el-col>
      </el-row>

      
      <div class="home-departments">
        <HomeDepartmentsExperiment/>
      </div>

      <HomeAccess/>

      <PageFooter :showList="false"/>

    </div>

    <div class="hidden-sm-and-up">

      <m-swiper/>
      <m-info/>
      <MSNS/>
      <m-departs/>
      <m-introduce/>
      <m-access/>

      <m-footer/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import PageHeader from "../components/PageHeader";
import PageFooter from "@/components/PageFooter";
import HomeDepartments from "@/components/home/HomeDepartments";
import HomeDepartmentsExperiment from "@/components/home/HomeDepartmentsExperiment";
import HomeAccess from "@/components/home/HomeAccess";
import HomeInformation from "@/components/home/HomeInformation";
import MTop from "../components/m/MTop";
import MSwiper from "../components/m/MSwiper";
import MInfo from "../components/m/MInfo";
import MOnline from "../components/m/MOnline";
import MDeparts from "../components/m/MDeparts";
import MIntroduce from "../components/m/MIntroduce";
import MAccess from "../components/m/MAccess";
import MFooter from "../components/m/MFooter";
import MSNS from "../components/m/MSNS"
// import HomeTopics from "@/components/home/HomeTopics";
import HomeSNS from "@/components/home/HomeSNS";
import HomeOnline from "@/components/home/HomeOnline";

export default {
  name: "Index",
  data() {
    return {
      picNameList: [
        'home_01.png',
        'home_02.png',
        'home_03.png',
        'IMG_0054.png',
        // 'img_main2.png',
        // 'img_main3.png',
        // 'img_main4.png',
        // 'img_main5.png',
      ],
      carouselHeight: ''
    }
  },
  components: {
    MFooter,
    MAccess,
    MIntroduce,
    MDeparts,
    MOnline,
    MInfo,
    MSwiper,
    MTop,
    HomeOnline,
    HomeSNS,
    // HomeTopics,
    HomeInformation,
    HomeAccess,
    HomeDepartments,
    PageFooter,
    PageHeader,
    HomeDepartmentsExperiment,
    MSNS
  },
  methods: {
    goDepartment(name) {
      this.$router.push(`/department/${name}`)
    },
    imgHeight() {
      let pageWidth = document.documentElement.clientWidth
      //pageWidth = pageWidth > 1284? 1284:pageWidth
      // console.log('浏览器页面宽度：'+ pageWidth)

      let factor = (1920 - pageWidth) / 1920
      // console.log(`因子为：${factor}`)
      this.carouselHeight = pageWidth * 700 / 1920 + 200 * factor
    }
  },
  mounted() {
    //页面宽度变时，carousel高度也跟着变
    window.onresize = () => {
      this.imgHeight()
    }

    // twitter 详细栏
    // let script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'https://platform.twitter.com/widgets.js';

    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.instagram.com/embed.js';
    document.body.appendChild(script);

    if(window.instgrm){
      window.instgrm.Embeds.process()
    }
  },
  beforeMount() {
    //刚加载页面时，carousel高度。
    this.imgHeight()
  }
}
</script>

<style scoped>
.carousel {
  margin-top: 10px;
  opacity: 0.8;
}
.home-online {
  position: absolute;
  bottom: 22%;
  right: 16%;
  z-index: 100;
}
.home-departments {
  background-color: #FFFDF7;
}
.info-blog {
  margin-top: 70px;
}


.el-carousel__item.is-animating {
    -webkit-transition: -webkit-transform 2s ease-in-out;
    transition: -webkit-transform 2s ease-in-out;
    transition: transform 2s ease-in-out;
    transition: transform 2s ease-in-out,-webkit-transform 2s ease-in-out;
}
</style>

<template>
<div id="info">
  <div class="info-title">お知らせ</div>

  <div>
    <van-swipe :loop="true" :width="pageWidth" autoplay="5000">
      <van-swipe-item v-for="item in infoList"v-bind:key="item.id" >
        <div class="info-list" @click="getInfo(item.id)">
          <div class="title">{{item.title}}</div>
          <div class="time">{{item.createTime}}</div>
          <div class="content">{{item.content}}</div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>

  <div class="to-info">
    <el-link :underline="false" @click="goTo('/information')">お知らせ一覧へ</el-link>
  </div>
</div>
</template>

<script>
import moment from "moment";

export default {
  name: "MInfo",
  data() {
    return {
      infoList: [],
      pageWidth:0
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    getInfo(id) {
      //就用这个方法吧，现成的。缺点是多写个文件。
      this.$router.push({
        path: '/infoitem',
        query: {id}
      });
    },
    adjustWidth(){
      this.pageWidth = document.documentElement.offsetWidth * 0.9;
    }
  },
  created() {
    this.pageWidth = document.documentElement.offsetWidth * 0.9;
    window.onresize = this.adjustWidth;
    axios.get(`${this.GLOBAL.host}/t/info/all`).then(resp => {
      resp.data.sort((a, b) => {
        let t1 = new Date(a.createTime)
        let t2 = new Date(b.createTime)
        return t2.getTime() - t1.getTime()
      })
      //取前五个
      resp.data = resp.data.slice(0, 5)
      resp.data.map(i => {
        i.createTime = moment(i.createTime).format('YYYY.MM.DD')
      })
      this.infoList = resp.data
    })
  }
}
</script>

<style scoped>
#info {
  margin: 1rem;
}
#info>div {
  margin: 12px 0
}
.info-title {
  font-size: 14px;
  color: #333;
  font-weight: bold;

  width: auto;
}
.info-list {
  background-color: #fff;
  width: 90%;
  height: 200px;
  margin: 0 auto 3rem;
  /* padding: 1rem 0; */

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-list /deep/ .title  {
  color: #1796c4;
  font-size: 18px;
  
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow : hidden;
  text-overflow: ellipsis;
}
.info-list /deep/ .time {
  margin: 1rem 0;
  font-size: 14px;
}
.info-list /deep/ .content {
  line-height: 1.4rem;
  font-size: 14px;
  color: #333;

  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow : hidden;
  text-overflow: ellipsis;
}

.to-info {
  text-align: right;
  font-size: 12px;
}
.to-info /deep/ span {
  color: #333;
}
</style>
